import React from "react";

import Layout from "../components/layout";
// import Seo from '../organisms/seo';
import { Link } from "../components/link";

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    {/* <Seo title='404: Not found' /> */}
    <div className="container">
      <div className="row">
        <div className="col-12 py-5">
          <h1>Pagina non trovata</h1>
          <p>La pagina che cerchi non è disponibile</p>
          <Link to="/">Torna alla homepage</Link>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
